<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>Gestion des nouveautés</h1>
      </header>
      <div>
        <router-link
          to="/administration/gestion-des-nouveautes/0"
          class="btn btn-primary float-right mt-4"
        >
          <i class="fa fa-plus"></i>
          <span>Nouveau</span>
        </router-link>

        <div class="table-responsive col-12">
          <table class="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-table-custom sm-2 table-logs">
            <thead>
            <tr>
              <th>Title</th>
              <th>Date de publication</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, rowIndex) in $store.state.releaselog.list" :key="rowIndex">
              <td data-label="Title :">{{ getTitleInFrench(row) }}</td>
              <td data-label="Slug :">{{ row.publishDate | dateFormat }}</td>
              <td
                class="actions"
                data-label="Actions :"
              >
                <router-link
                  tag="button"
                  :to="'/administration/gestion-des-nouveautes/'+row.id"
                  title="Renommer"
                >
                  <i class="fas fa-pen"></i>
                </router-link>
                <button title="Supprimer" @click="indexToDelete = rowIndex"><i class="fas fa-trash"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <DeleteReleaselogDialog
      v-if="indexToDelete !== false"
      :indexToDelete="indexToDelete"
      @closeDeleteReleaselogDialog="indexToDelete = false"
    ></DeleteReleaselogDialog>
  </div>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'
import DeleteReleaselogDialog from '@/components/Dialog/Releaselog/DeleteReleaselogDialog'

export default {
  name: 'ReleaselogList',
  components: { DeleteReleaselogDialog },
  data: () => ({
    successMessage: null,
    errors: [],
    loading: false,
    indexToDelete: false
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - Gestion des nouveautés'
    this.$store.dispatch('releaselog/getData', { uri: null, user: null })
  },
  methods: {
    getTitleInFrench (row) {
      if (row.translations && row.translations.fr) {
        return row.translations.fr.title
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.btn.btn-primary {
  color: #fff !important;
}

.btn-circle.btn-sm {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 4px 0 0 7px;

  i {
    font-size: 1em;
  }
}
</style>
